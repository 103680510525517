<template>
  <v-main>
    <v-dialog v-model="dialogs.cancel" max-width="500">
      <v-card>
        <v-card-text class="pa-5"> Are you sure? All progrss will be lost. </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="cancel()">Yes</v-btn>
          <span @click="dialogs.cancel = false">
            <cancel-button>No</cancel-button>
          </span>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container class="text-h5">
      <v-stepper v-model="stepper">
        <v-stepper-header>
          <v-stepper-step :complete="stepper > 1" step="1"> </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="stepper > 2" step="2"> </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="stepper > 3" step="3"> </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="stepper > 4" step="4"> </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <div>
              <div>
                <v-alert class="text-h6" type="success" color="primary" border="bottom">
                  Now that you have a secure account, you’ll want to think about which of the many
                  Peeps features to start with. Whichever one peaks your interest is a good starting
                  point.
                </v-alert>
              </div>
              <div class="text-center">
                <blob-feature
                  :width="blobFeatureWidth"
                  v-for="(b, i) in blobs"
                  :key="i"
                  :value="b.feature"
                ></blob-feature>
              </div>
              <div>
                <p>
                  But first you’ll need to add your parcel of land as a base to which you’ll then go
                  feature-by-feature. By the way, do you own or manage more than one parcel?
                </p>
                <p class="container text-center">
                  <v-chip
                    v-for="(e, index) in options.parcelManaged"
                    :key="index"
                    :color="e.color || 'peeps'"
                    dark
                    :outlined="formData.parcelManaged != e.value"
                    @click="onManagedParcelChange(e.value)"
                    :large="$vuetify.breakpoint.smAndUp"
                    :class="`mr-2 ${$vuetify.breakpoint.smAndUp ? 'text-h5' : ''}`"
                    ><v-icon v-if="formData.parcelManaged == e.value">mdi-check-circle</v-icon>
                    <v-icon v-else>mdi-checkbox-blank-circle-outline</v-icon>
                    <span class="ml-2"> {{ e.text }}</span></v-chip
                  >
                </p>
              </div>
              <v-btn outlined :color="color.next" @click="stepper = 2" v-if="formData.parcelManaged"
                >Continue</v-btn
              >
            </div>
          </v-stepper-content>

          <v-stepper-content step="2">
            <div>
              <div class="mt-7" v-if="formData.parcelManaged">
                <v-card flat>
                  <v-container>
                    <p>
                      You will need to know your map and parcel number. This is found on your tax
                      bills.
                    </p>

                    <v-sheet max-width="500" class="mx-auto">
                      <v-form @submit.prevent="" ref="parcelForm" v-model="validParcelForm">
                        <peeps-alert-animation
                          color="peeps-green"
                          ref="parcelNotFound"
                          message="Parcel not found"
                        >
                        </peeps-alert-animation>
                        <v-text-field v-model="formData.parcelId" outlined label="Parcel ID">
                        </v-text-field>
                        <v-btn
                          color="success"
                          class="text-capitalize"
                          @click="submitParcelId()"
                          :loading="loadings.processParcelId"
                          >Got It</v-btn
                        >
                        <v-btn color="info" class="ml-3 text-capitalize" @click="notSureParcelId()"
                          >Not Sure</v-btn
                        >
                      </v-form>
                    </v-sheet>
                  </v-container>
                </v-card>
              </div>
              <v-btn :color="color.prev" outlined @click="stepper = 1">Go Back</v-btn>
              <v-btn
                class="ml-2"
                :color="color.next"
                @click="stepper = 3"
                v-if="step.notSure || (step.gotParcelId && formData.mapParcel.parcelId)"
                >Continue</v-btn
              >
            </div>
          </v-stepper-content>

          <v-stepper-content step="3">
            <div>
              <div>
                <p v-if="step.notSure" class="mt-7">
                  If you don’t know your map-parcel and don’t have a tax bill handy, Peeps can look
                  it up for you in the public town records. Just type your street number and street
                  name below
                </p>
              </div>

              <div class="mt-6" v-if="step.notSure">
                <map-parcel-lookup
                  @click:parcel="onMapParcelClick"
                  :autofocus="false"
                  max-height="400px"
                >
                  <template v-slot:resultSuffix> : Click on the one that is yours </template>
                </map-parcel-lookup>
              </div>
              <div id="map-parcel" v-show="formData.mapParcel.parcelId" class="text-center mt-6">
                My map-parcel is {{ formData.mapParcel.parcelId }}
              </div>
            </div>

            <div class="mt-4">
              <v-btn :color="color.prev" outlined @click="stepper = 2">Go Back</v-btn>
              <v-btn
                class="ml-2"
                v-if="formData.mapParcel.parcelId"
                :color="color.next"
                @click="stepper = 4"
                >Continue</v-btn
              >
            </div>
          </v-stepper-content>

          <v-stepper-content step="4">
            <div>
              <div>
                <div class="my-5" v-if="formData.mapParcel.parcelId">
                  <v-card flat class="primary--text">
                    <v-container>
                      <div class="d-flex">
                        <div class="ml-3">
                          With that, you’re now ready to add your parcel.
                          {{
                            formData.parcelManaged == 'moreThanOne'
                              ? 'If you have more than one parcel, no worries, you’ll be able to add more later.'
                              : ''
                          }}
                          Just click the New Parcel button below and then complete the simple form:
                        </div>
                      </div>
                    </v-container>
                  </v-card>
                  <div class="text-center">
                    <v-btn color="primary" large @click="newParcel()"
                      >New Parcel <v-icon>mdi-plus</v-icon></v-btn
                    >
                    <span @click="dialogs.cancel = true">
                      <cancel-button large></cancel-button>
                    </span>
                  </div>
                </div>
              </div>
              <v-btn :color="color.prev" outlined @click="stepper = 3">Go Back</v-btn>
            </div>
          </v-stepper-content>
          <div class="text-right pa-2">
            <v-btn color="info" class="text-capitalize v-btn--active" rounded text to="/parcels"
              >Skip</v-btn
            >
          </div>
        </v-stepper-items>
      </v-stepper>
    </v-container>
  </v-main>
</template>

<script>
import rules from '.././../mixins/rules';
import parcel from '../../mixins/parcel';
export default {
  mixins: [rules, parcel],
  components: {
    MapParcelLookup: () => import('../../components/MapParcelLookup.vue'),
    CancelButton: () => import('@/components/Core/CancelButton'),
    PeepsAlertAnimation: () => import('@/components/Core/Alerts/PeepsAlertAnimation.vue'),
    BlobFeature: () => import('@/components/Core/BlobFeature.vue')
  },
  data() {
    return {
      color: {
        next: 'primary',
        prev: 'grey'
      },
      stepper: 1,
      dialogs: {
        cancel: false
      },
      loadings: {
        processParcelId: false
      },
      blobs: [
        { feature: 'water' },
        { feature: 'septic' },
        { feature: 'hvac' },
        { feature: 'solar' },
        { feature: 'tax' }
      ],
      formData: {
        parcelManaged: '',
        mapParcel: {},
        parcelId: ''
      },
      validParcelForm: true,
      options: {
        parcelManaged: [
          {
            text: 'One Parcel',
            value: 'one'
          },
          {
            text: 'More Than One Parcel',
            value: 'moreThanOne'
          }
        ]
      },
      step: {
        notSure: false,
        gotParcelId: false
      }
    };
  },
  computed: {
    blobFeatureWidth() {
      let width;
      if (this.$vuetify.breakpoint.width < 600) {
        width = 350 / 5;
      } else {
        width = 600 / 5;
      }
      width = `${width}px`;
      return width;
    }
  },
  methods: {
    onManagedParcelChange(value) {
      this.formData.parcelManaged = value;
      this.changeProfileData({ parcelManaged: this.formData.parcelManaged });
      this.stepper = 2;
    },
    goToMapParcel() {
      setTimeout(() => {
        this.$vuetify.goTo('#map-parcel', { duration: 1500 });
      }, 500);
    },
    onMapParcelClick(data) {
      this.formData.mapParcel = data;
      this.goToMapParcel();
    },
    newParcel() {
      this.$router.push(`/parcels?parcelId=${this.formData.mapParcel.parcelId || '0'}`);
    },
    cancel() {
      this.$router.push('/');
      this.logEvent('parcelIntroCancel');
    },
    async submitParcelId() {
      this.formData.mapParcel = {};
      this.$refs.parcelForm.validate();
      if (!this.validParcelForm) {
        return;
      }
      this.step.gotParcelId = true;
      this.loadings.processParcelId = true;
      // let parcelInfo = ""
      let parcelId = this.formData.parcelId;
      if (parcelId.split('-').length == 4) {
        parcelId = this.toMapParcel(parcelId);
      }
      let parcelData;
      try {
        parcelData = await this.getParcelInfo(parcelId);
      } catch (err) {
        console.log(err);
      }
      if (parcelData) {
        this.formData.mapParcel = {
          parcelId: parcelId,
          address: parcelData.street.street_address
        };
        this.stepper = 4;
        this.goToMapParcel();
      } else {
        this.$refs.parcelNotFound.animate();
      }
      this.step.notSure = false;
      this.loadings.processParcelId = false;
    },
    notSureParcelId() {
      this.step.notSure = true;
      this.step.gotParcelId = false;
      this.stepper = 3;
      this.formData.mapParcel = {};
    }
  }
};
</script>
